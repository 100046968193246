import React, { useState } from 'react';
import './ContactForm.css';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const customParams = {
    from_name: `Name: ${formState.name} | Telefone: ${formState.phone} | E-mail: ${formState.email}`,
    to_name: 'Engenho São Paulo',
    message: formState.message
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('service_4o8hzz6', 'template_qvsg864', customParams, 'fSHLP4AKG0XAnejbb')
      .then((_) => alert(t('contact.success')))
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="flex pl-5 pr-5 pt-5 flex-col w-full md:ml-0 md:w-full mb-10">
      <div className="flex flex-col grow px-5 py-12 w-full text-xs text-black bg-white rounded-3xl shadow-sm md:px-5 md:mt-10 md:max-w-full">
        <label htmlFor="name" className="mt-4 mb-2 md:max-w-full">{t('contact.what_is')}</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formState.name}
          onChange={handleChange}
          className="p-2 bg-transparent border-0 border-b-2 border-gray-300 focus:border-black focus:outline-none"
          required
        />

        <label htmlFor="email" className="mt-8 mb-2 md:max-w-full">{t('contact.email_to_talk')}</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formState.email}
          onChange={handleChange}
          className="p-2 bg-transparent border-0 border-b-2 border-gray-300 focus:border-black focus:outline-none"
          required
        />

        <label htmlFor="phone" className="mt-8 mb-2 md:max-w-full">{t('contact.number_to_contact')}</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={formState.phone}
          onChange={handleChange}
          className="p-2 bg-transparent border-0 border-b-2 border-gray-300 focus:border-black focus:outline-none"
          required
        />

        <label htmlFor="message" className="mt-8 mb-2 md:max-w-full">{t('contact.keep_your_message')}</label>
        <textarea
          id="message"
          name="message"
          value={formState.message}
          onChange={handleChange}
          className="p-2 bg-transparent border-0 border-b-2 border-gray-300 focus:border-black focus:outline-none h-32"
          required
        />

        <button
          type="submit"
          style={{ backgroundColor: '#85714D' }}
          className="submitItem justify-center items-center px-5 py-5 mt-8 text-xl font-bold text-center text-white border-0 md:px-5 md:mt-10 md:max-w-full"
        >
          {t('contact.send')}
        </button>
      </div>
    </form>

  );
}

export default ContactForm;
