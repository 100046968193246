import React from "react";
import './TheBestHomePageSection.css';
import { useTranslation } from "react-i18next";

const TheBestHomePageSection = () => {
    const { t } = useTranslation();

    return (
        <div className="the-best-home-page-section">
        <div className="the-best-section-parallax"></div>
        <div className="grid j">
                <span className="text-4xl font-semibold text-left the-best-section-content">
                    {t('home.biggest')} <br/>
                    {t('home.cane_liquior')} <br/>
                    {t('home.alambique_from_brasil')}
                </span>
            </div>
        </div>
    )
}

export default TheBestHomePageSection;