import React from 'react';
import './ScrollToTopButton.css';
import { ChevronUpIcon } from '@heroicons/react/16/solid';

const ScrollToTopButton = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <button onClick={scrollToTop} style={{
            zIndex: 1000
        }} id='scrollToTopButton' className="hidden fixed bottom-4 right-4 to-up-button text-white font-bold py-2 px-2 rounded-full shadow-lg">
            <ChevronUpIcon className="w-6 h-6" />
        </button>
    );
}

export default ScrollToTopButton;