import React from 'react';
import './MainProductsHomePageSection.css';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { DRINKS_LIST } from '../../constants/DrinksList';




const MainProductsHomePageSection = () => {
    const { t } = useTranslation();

    const imagens = [
        { id: DRINKS_LIST[1].id, logo: DRINKS_LIST[1].logo, src: DRINKS_LIST[1].logo1l, texto: DRINKS_LIST[1].description1 },
        { id: DRINKS_LIST[2].id, logo: DRINKS_LIST[2].logo, src: DRINKS_LIST[2].logo1l, texto: DRINKS_LIST[2].description1 },
        { id: DRINKS_LIST[3].id, logo: DRINKS_LIST[3].logo, src: DRINKS_LIST[3].logo1l, texto: DRINKS_LIST[3].description1 },

    ];

    return (
        <div className='mainProductsContent flex items-center justify-center h-screen'>
            <div className='fixMainProductContent'></div>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                    class: '.swiper-pagination'
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="container grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="flex relative justify-center h-[35vh] sm:h-[60vh] w-full">
                            <img src={[imagens[0].src]} loading="lazy" />
                        </div>

                        <div className="relative flex px-5 items-center justify-center flex-wrap text-sm">
                            <div className="flex h-[12vh] sm:h-[20vh] items-center justify-center w-full sm:w-auto">
                                <img src={imagens[0].logo} loading="lazy" alt="Logo" className="max-h-full mx-auto" />
                            </div>
                            <div className='w-full my-3 sm:py-0 text-center text-white font-bold text-sm sm:text-base leading-5'>
                                {t(`${imagens[0].texto}`)}
                            </div>
                            <div className='w-full'>
                                <Link to={`/bebida-detalhe/${imagens[0].id}`} className='relative w-full flex justify-center sm:absolute mt-[40px] sm:mt-[-40px] sm:justify-start'>
                                    <button style={{ backgroundColor: '#85714D' }} className="w-full sm:w-full flex bg-white justify-center items-center py-5 px-7 font-bold text-white">
                                        <div style={{ color: 'white' }} className='font-black text-xl'>
                                            {t('common.to_know')}
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="container grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="flex relative justify-center h-[35vh] sm:h-[60vh] w-full">
                            <img src={[imagens[1].src]} loading="lazy" />
                        </div>

                        <div className="relative flex px-5 items-center justify-center flex-wrap text-sm">
                            <div className="flex h-[12vh] sm:h-[20vh] items-center justify-center w-full sm:w-auto">
                                <img src={imagens[1].logo} loading="lazy" alt="Logo" className="max-h-full mx-auto" />
                            </div>
                            <div className='w-full my-3 sm:py-0 text-center text-white font-bold text-sm sm:text-base leading-5'>
                                {t(`${imagens[1].texto}`)}
                            </div>
                            <div className='w-full'>
                                <Link to={`/bebida-detalhe/${imagens[1].id}`} className='relative w-full flex justify-center sm:absolute mt-[40px] sm:mt-[-40px] sm:justify-start'>
                                    <button style={{ backgroundColor: '#85714D' }} className="w-full sm:w-full flex bg-white justify-center items-center py-5 px-7 font-bold text-white">
                                        <div style={{ color: 'white' }} className='font-black text-xl'>
                                            {t('common.to_know')}
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="container grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="flex relative justify-center h-[35vh] sm:h-[60vh] w-full">
                            <img src={[imagens[2].src]} loading="lazy" />
                        </div>

                        <div className="relative flex px-5 items-center justify-center flex-wrap text-sm">
                            <div className="flex h-[12vh] sm:h-[20vh] items-center justify-center w-full sm:w-auto">
                                <img src={imagens[2].logo} loading="lazy" alt="Logo" className="max-h-full mx-auto" />
                            </div>
                            <div className='w-full my-3 sm:py-0 text-center text-white font-bold text-sm sm:text-base leading-5'>
                                {t(`${imagens[2].texto}`)}
                            </div>
                            <div className='w-full'>
                                <Link to={`/bebida-detalhe/${imagens[2].id}`} className='relative w-full flex justify-center sm:absolute mt-[40px] sm:mt-[-40px] sm:justify-start'>
                                    <button style={{ backgroundColor: '#85714D' }} className="w-full sm:w-full flex bg-white justify-center items-center py-5 px-7 font-bold text-white">
                                        <div style={{ color: 'white' }} className='font-black text-xl'>
                                            {t('common.to_know')}
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

        </div>
    );
}

export default MainProductsHomePageSection;