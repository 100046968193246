import React, { useEffect } from 'react';
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import History1 from "../../assets/history/history-1.jpg";
import History2 from "../../assets/history/history-2.jpg";
import History3 from "../../assets/history/history-3.jpg";
import History4 from "../../assets/history/history-4.jpg";
import './AboutUsPage.css';
import { useTranslation } from 'react-i18next';

const AboutUsPage = () => {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = 'Engenho São Paulo | O engenho';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col bg-white">
      <Header />
      <div style={{
        height: '50vh',
      }} className="flex flex-col px-5 w-full ">
        <div className="flex-1 relative self-start mt-3 ml-11 font-bold text-center text-[300px] text-black text-opacity-10 max-md:max-w-full max-md:text-3xl">
          {t('common.1909')}
        </div>
        <div style={{
          position: 'absolute',
          top: 200,
          left: 10
        }} className='flex-1'>
          <h1 className="text-5xl font-bold mb-4">{t('about_us.welcome')}</h1>
          <p className="mb-4">{t('about_us.from_now')}</p>
        </div>
      </div>

      <div className='relative'>
        <div className='backgroundAboutUs'></div>
        <div className="grid relative z-10 md:grid-cols-2 place-items-center p-5">
          <img
            loading="lazy"
            src={History1}
          />
          <div className="mx-auto w-full text-center self-start  p-5 center mt-0 mb-20 text-2xl text-black max-md:mt-0 max-md:max-w-full">
            {t('about_us.first_content')}
          </div>
        </div>

        <div className="grid md:grid-cols-2 place-items-center gap-4 p-5">
          <div className="mx-auto order-2 sm:order-1 w-full text-center self-start  p-5 mt-0 mb-20 text-2xl text-black max-md:mt-0 max-md:max-w-full">
            {t('about_us.second_content')}
          </div>
          <img
            loading="lazy"
            className='order-1 sm:order-2'
            src={History2}
          />
        </div>


        <div className="grid md:grid-cols-2 place-items-center gap-4 p-5">
          <img
            loading="lazy"
            src={History3}
          />
          <div className="mx-auto w-full text-center self-start p-5 mt-0 mb-20 text-2xl text-black max-md:mt-0 max-md:max-w-full">
            {t('about_us.third_content')}
          </div>
        </div>

        <div className="grid md:grid-cols-2 place-items-center gap-4 p-5">
          <div className="mx-auto order-2 sm:order-1 w-full text-center self-start p-5 mt-0 mb-20 text-2xl text-black max-md:mt-0 max-md:max-w-full">
            {t('about_us.fourth_content')}
          </div>
          <img
            loading="lazy"
            src={History4}
            className='order-1 sm:order-2'
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUsPage;