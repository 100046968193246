import React, { useEffect } from 'react';

const FlagDialog = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    const closeOnEsc = (e) => {
      if (e.keyCode === 27) onClose(); // KeyCode 27 é o ESC
    };

    window.addEventListener('keydown', closeOnEsc);
    return () => window.removeEventListener('keydown', closeOnEsc);
  }, [onClose]);

  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    if (e.target.id === "backdrop") onClose();
  };

  return (
    <div id="backdrop" className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center" onClick={handleBackdropClick}>
      <div className="bg-white p-4 rounded-lg mx-auto overflow-auto max-h-[80vh] max-w-80 sm:max-w-[100vh]" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default FlagDialog;