import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from "../Logo/Logo";
import './Header.css';
import BrasilFlag from "../Flags/BrasilFlag";
import USAFlag from "../Flags/USAFlag";
import { useTranslation } from "react-i18next";



const Header = () => {
    const { t } = useTranslation()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    
    const navigation = [
        { name: t('common.home'), path: '/' },
        { name: t('common.our_drinks'), path: '/nossas-bebidas' },
        { name: t('common.about_us'), path: '/o-engenho' },
        { name: t('common.contact'), path: '/contato' },
    ]

    return (
        <header className="bg-white absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">

                <Link to={'/'} className="flex lg:flex-1">
                    <div className="-m-1.5 p-1.5">
                        <span className="sr-only">{t('company.name')}</span>
                        <Logo color='black' />
                    </div>
                </Link>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">{t('menu.open')}</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <Link className="text-sm font-semibold leading-6 text-gray-900" key={item.name} to={item.path}>
                            <div className="nav-item-effects">
                                {item.name}
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <div className="flex gap-4 self-stretch my-auto">
                        <BrasilFlag></BrasilFlag>
                        <USAFlag></USAFlag>
                    </div>
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">{t('company.name')}</span>
                            <Logo color='black' />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">{t('menu.close')}</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <ul><Link className="text-sm font-semibold leading-6 text-gray-900" key={item.name} to={item.path}> {item.name} </Link></ul>
                                ))}
                            </div>
                            <div className="py-6">
                                <div className="flex gap-4 self-stretch my-auto">
                                    <BrasilFlag></BrasilFlag>
                                    <USAFlag></USAFlag>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}

export default Header;