import React from 'react';
import './VideoHomePageSection.css';
import { useTranslation } from 'react-i18next';

const VideoHomePageSection = ({ VideoSrc }) => {

    const { t } = useTranslation();

    return (
        <div className="containerFirst">
            <video autoPlay muted playsInline loop loading="lazy" className='videoBackground'>
                <source src={VideoSrc} type="video/mp4" />
                {t('home.html_5_not_support')}
            </video>
            <div className='firstCContent'>
                <div className='zeroContent'>
                    <div className="relative text-3xl text-white whitespace-nowrap font-bold tracking-tighter max-md:mt-10 max-md:max-w-full">
                        {t('home.more_than')}
                    </div>
                    <div className="relative text-8xl whitespace-nowrap text-white font-black max-md:max-w-full max-md:text-6xl">
                        {t('home.100_years')}
                    </div>
                    <div className="relative text-white whitespace-nowrap font-bold text-5xl tracking-tighter  max-md:text-4xl">
                        {t('home.making_history')}
                    </div>
                </div></div>
        </div>
    );
};

export default VideoHomePageSection;
