import * as React from "react";
import useAgeConsent from '../../hooks/useAgeConsent';
import BrasilFlag from "../Flags/BrasilFlag";
import USAFlag from "../Flags/USAFlag";
import { useTranslation } from "react-i18next";


const Consent = () => {
  const [consentGiven, giveConsent] = useAgeConsent();
  const { t } = useTranslation();

  if (consentGiven) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10000,
    }}>
      <div className="flex justify-center items-center px-16 py-12 w-full bg-zinc-300 bg-opacity-90 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center mt-32 mb-11 max-w-full w-[569px] max-md:my-10">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e39deea1dadaa8716198760e009d44b6433f92c2d0b99e9e07aede17e7fdc6ce?"
            className="max-w-full aspect-square w-[155px]"
          />
          <div className="self-stretch mt-32 text-lg tracking-tight text-center text-black max-md:mt-10 max-md:max-w-full">
            {t('consent.initial')}
          </div>
          <div className="mt-20 text-3xl font-bold text-center text-black tracking-[3.2px] max-md:mt-10 max-md:max-w-full">
            <span className="">{t('consent.are_you_older')}</span>
          </div>
          <a>
            <button onClick={giveConsent} style={{
              backgroundColor: '#85714D',
              color: 'white'
            }} className=" justify-center items-center px-16 py-4 mt-16 max-w-full text-2xl font-bold text-center text-black whitespace-nowrap bg-zinc-300 tracking-[2.4px] w-[400px] max-md:px-5 max-md:mt-10">
              {t('common.yes')}
            </button>
          </a>
          <a href="https://google.com.br">
            <button style={{color: '#85714D'}} className="mt-14 text-2xl font-bold text-center text-black tracking-[2.4px] max-md:mt-10">
            {t('common.no')}
            </button>
          </a>
          <div className="flex gap-4 mt-24 w-[82px] max-md:mt-10">
            <BrasilFlag></BrasilFlag>
            <USAFlag></USAFlag>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Consent;