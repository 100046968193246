import React, { useState } from "react";
import './InfoHomePageSection.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWater } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import FlagDialog from "../FlagDialog/FlagDialog";
import Slider from "react-slick";

import Img01 from '../../assets/awards/1.png';
import Img02 from '../../assets/awards/2.png';
import Img03 from '../../assets/awards/3.png';
import Img04 from '../../assets/awards/4.png';
import Img05 from '../../assets/awards/5.png';
import Img06 from '../../assets/awards/6.png';
import Img07 from '../../assets/awards/7.png';
import Img08 from '../../assets/awards/8.png';
import Img09 from '../../assets/awards/9.png';


const InfoHomePageSection = () => {
    const { t } = useTranslation();

    const [isDialogOpen, setDialogOpen] = useState(false);

    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);

    const baseSettings = {
        dots: false,
        infinite: true,
        speed: 2500,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        pauseOnHover: true,
    };

    // Ajusta slidesToShow com base no tamanho da janela
    const slidesToShow = window.innerWidth < 768 ? 2 : 4;

    // Combina as configurações base com slidesToShow ajustado
    const settings = {
        ...baseSettings,
        slidesToShow: slidesToShow,
    };

    const awardsImages = [
        Img01,
        Img02,
        Img03,
        Img04,
        Img05,
        Img06,
        Img07,
        Img08,
        Img09
    ];


    return (
        <div className="relative flex flex-col w-full bg-white max-md:max-w-full">
            <div className="flex flex-col px-20 pb-7 bg-white max-md:px-5 max-md:max-w-full">

                <div style={{ position: 'absolute', marginTop: -130 }} className="self-start font-black text-center text-[130px] sm:text-[230px] text-black text-opacity-5">
                    {t('common.1909')}
                </div>
                <div className="self-center mt-0 w-full max-w-100 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                        <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
                            <div style={{ color: '#E1D3B7' }} className="flex flex-col grow text-5xl font-black max-md:text-4xl">
                                <div className="mt-12 max-md:text-4xl">{t('home.more_4_decade')}</div>
                                <div className="self-start text-xl text-black">
                                    {t('home.exporting')}
                                </div>
                                <div className="mt-10 sm:mt-20 max-md:text-4xl">
                                    {t('home.more_10_products')}
                                </div>
                                <div className="text-xl text-black">
                                    {t('home.from_same_place')}
                                </div>
                                <div className="mt-10 sm:mt-20 max-md:text-4xl">
                                    {t('home.more_20')}
                                </div>
                                <div className="text-xl text-black">
                                    {t('home.where_award')}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
                            <div style={{ color: '#E1D3B7' }} className="flex flex-col text-5xl font-black  max-md:max-w-full max-md:text-4xl">
                                <div className="mt-10 max-md:max-w-full max-md:text-4xl">
                                    {t('home.more_150_millions')}
                                </div>
                                <div className="text-xl text-black max-md:max-w-full">
                                    <div>{t('home.from_size')}</div>
                                    <span className="text-sky-500 text-base leading-3 tracking-tighter">
                                        {t('home.100_olympic')}
                                        <FontAwesomeIcon className="pl-2" icon={faWater}></FontAwesomeIcon>
                                    </span>
                                </div>
                                <div className="mt-9 sm:mt-20 max-md:text-4xl">
                                    {t('home.more_4_generation')}
                                </div>
                                <div className="mt-2 text-xl text-black max-md:max-w-full">
                                    {t('home.same_distilled')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={'/o-engenho'}>
                    <button className="know-more-about-effects justify-center items-start py-5 pr-16 pl-4 mt-20 mb-4 ml-0  max-w-full text-xl sm:text-2xl font-bold text-center text-white whitespace-nowrap border border-solid bg-stone-300 border-[color:var(--l\_esp,#E1D3B7)] w-100 max-md:px-5 max-md:mt-10">
                        {t('home.know_more')}
                    </button>
                </Link>


                <div className="pt-10">
                    <span className="text-4xl font-black"> {t('home.awards')} </span>

                    <Slider className='py-10' {...settings}>

                        {awardsImages.reverse().map((imagem, index) => (
                            <div className="px-10">
                                <img key={index} className="responsive-image" src={imagem} alt={`Imagem ${index + 1}`} />
                            </div>
                        ))}

                    </Slider>

                    <button onClick={openDialog} className="know-more-about-effects justify-center items-start py-5 pr-16 pl-4 mt-10 mb-4 ml-0 text-xl sm:text-2xl font-bold text-center text-white whitespace-nowrap border border-solid bg-stone-300 border-[color:var(--l\_esp,#E1D3B7)] w-80 max-md:px-5 max-md:mt-10">
                        {t('button.view_all_awards')}
                    </button>


                    <FlagDialog isOpen={isDialogOpen} onClose={closeDialog}>
                        <h2 className="text-4xl font-semibold mb-8">{t('dialog.titles.awards')}</h2>
                        <div className="mb-6">
                            <h3 className="font-semibold text-lg mb-2">{t('dialog.titles.cachaca_sp_original')}</h3>
                            <ul className="list-disc pl-5">
                                {t('dialog.awards.sp_original', { returnObjects: true }).map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-6">
                            <h3 className="font-semibold text-lg mb-2">{t('dialog.titles.cachaca_sp_cristal')}</h3>
                            <ul className="list-disc pl-5">
                                {t('dialog.awards.sp_cristal', { returnObjects: true }).map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-6">
                            <h3 className="font-semibold text-lg mb-2">{t('dialog.titles.sp_red_usa')}</h3>
                            <ul className="list-disc pl-5">
                                {t('dialog.awards.sp_red_usa', { returnObjects: true }).map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-6">
                            <h3 className="font-semibold text-lg mb-2">{t('dialog.titles.sp_blended_black_usa')}</h3>
                            <ul className="list-disc pl-5">
                                {t('dialog.awards.sp_blended_black_usa', { returnObjects: true }).map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-6">
                            <h3 className="font-semibold text-lg mb-2">{t('dialog.titles.cachaca_caipira')}</h3>
                            <ul className="list-disc pl-5">
                                {t('dialog.awards.cachaca_caipira', { returnObjects: true }).map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-6">
                            <h3 className="font-semibold text-lg mb-2">{t('dialog.titles.cachaca_caipira_amburana')}</h3>
                            <ul className="list-disc pl-5">
                                {t('dialog.awards.cachaca_caipira_amburana', { returnObjects: true }).map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-6">
                            <h3 className="font-semibold text-lg mb-2">{t('dialog.titles.cigana')}</h3>
                            <ul className="list-disc pl-5">
                                {t('dialog.awards.cigana', { returnObjects: true }).map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-6">
                            <h3 className="font-semibold text-lg mb-2">{t('dialog.titles.cigana_carvalho')}</h3>
                            <ul className="list-disc pl-5">
                                {t('dialog.awards.cigana_carvalho', { returnObjects: true }).map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="mb-6">
                            <h3 className="font-semibold text-lg mb-2">{t('dialog.titles.cigana_heritage')}</h3>
                            <ul className="list-disc pl-5">
                                {t('dialog.awards.cigana_heritage', { returnObjects: true }).map((award, index) => (
                                    <li key={index}>{award}</li>
                                ))}
                            </ul>
                        </div>
                    </FlagDialog>
                </div>

            </div>

        </div>
    );
}

export default InfoHomePageSection;