import React, { useEffect } from 'react';
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer';
import { DRINKS_LIST } from '../../constants/DrinksList';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OurDrinksPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'Engenho São Paulo | Nossas bebidas';
  }, []);

  return (
    <div>
      <Header />
      <div style={{
        height: '10vh',
        width: '100%',
        color: 'white'
      }}></div>
      <div className="mt-16 max-md:max-w-full">
        <div className="mt-16 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
            <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full md:order-1">
              <div
                style={{
                  backgroundImage: `url(${DRINKS_LIST[0].image1})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center right',
                  backgroundRepeat: 'no-repeat',
                  height: '400px'
                }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
              </div>
            </div>
          <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full">
            <div className="flex relative justify-center items-center flex-col grow  mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[0].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[0].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className=" relative md:absolute justify-center items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div className="mt-16 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[1].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center right',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full">
            <div className="flex relative justify-center items-center flex-col grow  mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[1].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[1].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className=" relative md:absolute justify-center items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full order-2 md:order-1">
            <div className="relative flex flex-col justify-center items-center grow mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[2].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[2].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className="justify-center relative md:absolute items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
          <div className="flex h-50 flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full order-1 md:order-2">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[2].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center left',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[3].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center right',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full">
            <div className="flex relative justify-center items-center flex-col grow  mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[3].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[3].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className=" relative md:absolute justify-center items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full order-2 md:order-1">
            <div className="relative flex flex-col justify-center items-center grow mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[4].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[4].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className="justify-center relative md:absolute items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
          <div className="flex h-50 flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full order-1 md:order-2">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[4].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center left',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[5].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center right',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full">
            <div className="flex relative justify-center items-center flex-col grow  mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[5].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[5].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className=" relative md:absolute justify-center items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full order-2 md:order-1">
            <div className="relative flex flex-col justify-center items-center grow mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[6].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[6].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className="justify-center relative md:absolute items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
          <div className="flex h-50 flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full order-1 md:order-2">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[6].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center left',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[7].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center right',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full">
            <div className="flex relative justify-center items-center flex-col grow  mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[7].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[7].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className=" relative md:absolute justify-center items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full order-2 md:order-1">
            <div className="relative flex flex-col justify-center items-center grow mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[8].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[8].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className="justify-center relative md:absolute items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
          <div className="flex h-50 flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full order-1 md:order-2">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[8].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center left',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
        </div>
      </div>


      <div className="mt-16 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[9].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center right',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full">
            <div className="flex relative justify-center items-center flex-col grow  mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[9].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[9].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className=" relative md:absolute justify-center items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
        </div>
      </div>



      <div className="mt-16 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full order-2 md:order-1">
            <div className="relative flex flex-col justify-center items-center grow mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[10].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[10].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className="justify-center relative md:absolute items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
          <div className="flex h-50 flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full order-1 md:order-2">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[10].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center left',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
        </div>
      </div>


      <div className="mt-16 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
            <div
              style={{
                backgroundImage: `url(${DRINKS_LIST[11].image1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center right',
                backgroundRepeat: 'no-repeat',
                height: '400px'
              }} className="grow items-start pt-6 pr-16 pb-12 pl-6 w-full text-xs font-black tracking-wider text-white whitespace-nowrap bg-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[50%] max-md:ml-0 max-md:w-full">
            <div className="flex relative justify-center items-center flex-col grow  mt-2 text-center text-black max-md:mt-10 max-md:max-w-full">
              <div className="self-center text-3xl tracking-widest w-70">
                {DRINKS_LIST[11].name}
              </div>
              <Link to={`/bebida-detalhe/${DRINKS_LIST[11].id}`}
                style={{
                  bottom: 0,
                  width: '100%',
                  backgroundColor: '#85714D'
                }}
                className=" relative md:absolute justify-center items-center px-16 py-7 mt-44 text-2xl font-bold text-white whitespace-nowrap max-md:px-5 max-md:mt-10 max-md:max-w-full">
                {t('common.to_know')}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div style={{
        height: '10vh',
        width: '100%',
        color: 'white'
      }}></div>

      <Footer />
    </div>
  );
}

export default OurDrinksPage;


