import React from "react";
import { useTranslation } from 'react-i18next';
import BandeiraBrasil from "../../assets/bandeiras/brasil.png";

const BrasilFlag = () => {
    const { i18n } = useTranslation();
    
    return (
        <button onClick={() => i18n.changeLanguage('pt')}>
            <img
                loading="lazy"
                src={BandeiraBrasil}
                className="rounded-lg aspect-[1.56] w-[33px]"
            />
        </button>
    );
}

export default BrasilFlag;