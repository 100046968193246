import React, { useEffect } from 'react';
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import VideoHomePageSection from '../../components/VideoHomePageSection/VideoHomePageSection';
import HomeVideo from "../../assets/videos/header_home.mp4"
import HomeVideoMobile from "../../assets/videos/header_home_mobile.mp4"
import MainProductsHomePageSection from '../../components/MainProductsHomePageSection/MainProductsHomePageSection';
import InfoHomePageSection from '../../components/InfoHomePageSection/InfoHomePageSection';
import YoutubeHomePageSection from '../../components/YoutubeHomePageSection/YoutubeHomePageSection';
import TheBestHomePageSection from '../../components/TheBestHomePageSection/TheBestHomePageSection';

const HomePage = () => {

  useEffect(() => {
    document.title = 'Engenho São Paulo | Início';
  }, []);

  return (
    <div className="flex flex-col bg-stone-300">
      <Header />
      <VideoHomePageSection VideoSrc={window.width < 768 ? HomeVideoMobile : HomeVideo} />
      <TheBestHomePageSection />
      <MainProductsHomePageSection />
      <div style={{
        height: '15vh',
        backgroundColor: 'white',
        width: '100%'
      }}></div>
      <InfoHomePageSection />
      <YoutubeHomePageSection />
      <Footer />
    </div>
  );
}

export default HomePage;