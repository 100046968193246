import LogoBlack from '../../assets/logo/logo-black.png'
import LogoWhite from '../../assets/logo/logo-white.png'

const Logo = ({ color = 'white' }) => {
    let selo;
  switch (color) {
    case 'white':
        selo = LogoWhite;
        break;
    case 'black':
        selo = LogoBlack
        break;
    default:
        selo = LogoWhite
  }
    return (
        <img src={selo} className="self-stretch aspect-square w-[72px]"
            loading="lazy" alt="My SVG" style={{ fill: color }} />
    );
}

export default Logo;