import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import routeConfig from './routeConfig';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Consent from './components/Consent/Consent';
import GoogleAnalytics from './services/GoogleAnalytics';

function Main() {
  const routesElement = useRoutes(routeConfig);
  return routesElement;
}

const AppWrapper = () => {
  useEffect(() => {
    const fab = document.getElementById('scrollToTopButton');
    const headerHeight = 200;

    const toggleFABVisibility = () => {
      if (window.scrollY > headerHeight) {
        fab.classList.remove('hidden');
      } else {
        fab.classList.add('hidden');
      }
    };

    window.addEventListener('scroll', toggleFABVisibility);

    return () => window.removeEventListener('scroll', toggleFABVisibility);
  }, []);


  return (
    <BrowserRouter>
      <GoogleAnalytics trackingId="G-2K1FLVCB2M" />

      <Suspense fallback="Loading...">
        <Consent />
      </Suspense>
      <Main />
      <ScrollToTopButton />
    </BrowserRouter>
  );
};

export default AppWrapper;
