import React from "react";
import './YoutubeHomePageSection.css';
import { useTranslation } from "react-i18next";

const YoutubeHomePageSection = () => {

  const { t } = useTranslation();

  return (
    <div className="mainYoutubeContent h-[110vh] sm:h-[40vh]">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
            <div className="relative w-full h-0" style={{ paddingBottom: '56.25%' }}>
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/5DzQaorOYU4?si=_hk25bsII7iQWcVK"
                frameBorder="0"
                allowFullScreen
                title="Cachaça São Paulo - Bota na mesa pra ver se não anima!"
              ></iframe>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
            <div className="relative w-full h-0" style={{ paddingBottom: '56.25%' }}>
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/XQnGpedRA0Y?si=2diTi95VRLm6oG8"
                frameBorder="0"
                allowFullScreen
                title="Cachaça São Paulo - Aposta na que tem história!"
              ></iframe>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4">
            <div className="relative w-full h-0" style={{ paddingBottom: '56.25%' }}>
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/pAAUsEWq3Ic?si=sedR8ZYhpkJvhW8R"
                frameBorder="0"
                allowFullScreen
                title="Cachaça São Paulo - São Longuinho!"
              ></iframe>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#85714D' }} className="flex justify-left pl-5 h-[120px] w-100 py-5 mx-5 mt-[-50px]">
          <div className="text-white text-xl font-bold mt-12">
            {t('home.history_in_video')}
          </div>
        </div>
      </div>

    </div>
  );
}

export default YoutubeHomePageSection;