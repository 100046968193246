import React, { useEffect } from 'react';
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ContactForm from '../../components/ContactForm/ContactForm';
import './ContactPage.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const ContactPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = 'Engenho São Paulo | Contato';
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex flex-col contentContact">
            <Header />
            <div className="self-center mt-32 w-full max-w-[1334px] md:mt-10 md:max-w-full">
                <div className="flex gap-5 flex-col mt-10 md:flex-row md:gap-5">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col grow px-5 mt-1.5 md:mt-10">
                            <div className="text-7xl mt-10 sm:mt-5 font-bold text-black md:text-4xl">
                                {t('contact.name')}
                            </div>
                            <div className="mt-20 md:mt-10 md:pr-5">
                                <div className="flex gap-5 flex-col md:flex-row md:gap-5">
                                    <div className="flex flex-col w-full">
                                        <div className="flex flex-col text-xs text-black mt-10">
                                            <div className="text-lg font-bold">{t('footer.come_see_us')}</div>
                                            <div className="mt-3.5">Faz Engenho São Paulo, S/N.</div>
                                            <div className="mt-2">Zona Rural</div>
                                            <div className="mt-2.5">Cruz do Espírito Santo - PB</div>
                                            <div className="mt-2">CEP: 58.337-000</div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="flex flex-col grow text-lg font-bold text-black mt-10">
                                            <div>{t('contact.call_us')}</div>
                                            <div className="mt-3 text-xs">+55 (83) 99645-0093</div>

                                            <div className="mt-8">{t('contact.send_a_message')}</div>
                                            <div className="mt-2.5 text-xs">
                                                contato@engenhosaopaulo.com.br
                                            </div>
                                            <a className="mt-2.5 text-xs" href="https://wa.me/5583996450093?text=Olá, vim pelo site." target="_blank" rel="noopener noreferrer">
                                                {t('contact.send_whats')} <FontAwesomeIcon icon={faWhatsapp} color='#128c7e' size='xl' loading="lazy" className="aspect-square" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative overflow-hidden" style={{ paddingTop: '56.25%' }}>
                                <iframe
                                    className="absolute top-0 left-0 w-full h-full border-0 mt-5"
                                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBuN-ZhksArVfhDFXClj8vATHZRcVJkRPY&q=place_id:ChIJj5kfNv7wrAcRmh0ao73dLQI"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                    <ContactForm />
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default ContactPage;