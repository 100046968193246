import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Header />
            <div className="flex items-center justify-center h-screen bg-gray-100">
                <div className="text-center">
                    <h1 className="text-6xl font-bold text-black">{t('not_found.code')}</h1>
                    <p className="text-lg text-gray-700">{t('not_found.page')}</p>
                </div>
            </div>
            <Footer />
        </div>

    );
};

export default NotFoundPage;