import React from "react";
import { useTranslation } from 'react-i18next';
import BandeiraUSA from "../../assets/bandeiras/united-states.png";

const USAFlag = () => {
    const { i18n } = useTranslation();
    
    return (
        <button onClick={() => i18n.changeLanguage('en')}>
            <img
                loading="lazy"
                src={BandeiraUSA}
                className="rounded-lg aspect-[1.56] w-[33px]"
            />
        </button>
    );
}

export default USAFlag;