import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../Logo/Logo";
import './Footer.css';
import { faFacebook, faInstagram, faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation()
    return(
        <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">
        <div className="flex flex-col px-16 pt-12 pb-3.5 w-full bg-black max-md:px-5 max-md:max-w-full">
          <div className="mt-2.5 max-md:pr-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col text-xl text-white max-md:mt-10">
                  <Logo />
                  <div className="mt-11 text-xl font-bold max-md:mt-10" style={{ color: '#E1D3B7' }}>
                    {t('footer.since')}
                  </div>
                  <div className="text-base mt-12 max-md:mt-10">
                  {t('footer.message')}
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-2 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow mt-28 text-lg text-white max-md:mt-10">
                  <div className="text-xl font-bold" style={{ color: '#E1D3B7' }}>
                  {t('footer.come_see_us')}
                  </div>
                  <div className="text-base mt-12 max-md:mt-10">
                    Faz Engenho São Paulo, S/N.
                  </div>
                  <div className="text-base mt-1">Zona Rural</div>
                  <div className="text-base mt-1">Cruz do Espírito Santo - PB</div>
                  <div className="text-base mt-1">CEP: 58.337-000</div>
                </div>
              </div>
              <div className="flex flex-col ml-2 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col mt-28 self-stretch my-auto max-md:mt-10">
                  <div style={{ color: '#E1D3B7' }} className="text-xl font-bold whitespace-nowrap">
                  {t('footer.social_medias')}
                  </div>
                  <div className="flex gap-5 justify-between items-start mt-11 max-w-full w-[152px] max-md:mt-10">
                    <a href="https://www.instagram.com/engsaopaulo/" target="_blank">
                      <FontAwesomeIcon icon={faInstagram} size='2xl' loading="lazy" className="ig-icon-effect flex-1 shrink-0 w-full aspect-square"/>
                    </a>
                    <a href="https://www.facebook.com/engsaopaulo/?locale=pt_BR" target="_blank">
                      <FontAwesomeIcon icon={faFacebook} size='2xl' loading="lazy" className="fb-icon-effect flex-1 shrink-0 w-full aspect-square"/>
                    </a>
                    <a href="https://www.youtube.com/@engenhosaopaulo6312" target="_blank">
                      <FontAwesomeIcon icon={faYoutube} size='2xl' loading="lazy" className="yt-icon-effect flex-1 shrink-0 w-full aspect-square"/>
                    </a>
                    <a href="https://wa.me/5583996450093?text=Olá, vim pelo site." target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faWhatsapp} size='2xl' loading="lazy" className="wpp-icon-effect flex-1 shrink-0 w-full aspect-square"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-5 justify-between items-start mt-11 w-full text-white max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col self-start text-lg">
              <div className="text-base whitespace-nowrap">SAC: +55 (83) 99645-0093</div>
              <Link to={'/contato'}>
              <button className="talk-us-effects justify-center items-start py-5 pr-16 pl-4 mt-7 font-bold border border-solid border-[color:var(--l\_esp,#E1D3B7)] max-md:pr-5">
                <div style={{ color: '#E1D3B7' }}>
                {t('footer.talk_with_us')}
                </div>
              </button></Link>
              <div className="mt-7 text-xs whitespace-nowrap">
              {t('footer.copyright')}
              </div>
            </div>
            <div className="flex gap-5 justify-between self-end mt-28 text-xs text-right max-md:mt-10">
              <div className="flex-auto self-end mt-6">
              {t('footer.developed_by')} <a target="_blank" href="https://www.flaglab.com.br">Flag Lab</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Footer;