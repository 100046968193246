

import HomePage from './pages/HomePage/HomePage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import ContactPage from './pages/ContactPage/ContactPage';
import OurDrinksPage from './pages/OurDrinksPage/OurDrinksPage';
import OurDrinksDetailPage from './pages/OurDrinksDetailPage/OurDrinksDetailPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

const routeConfig = [
    {
      path: '/',
      element: <HomePage/>,
      name: "Início"
    },
    {
      path: '/nossas-bebidas',
      element: <OurDrinksPage/>,
      name: "Nossas bebidas"
    },
    {
      path: '/o-engenho',
      element: <AboutUsPage/>,
      name: "O engenho"
    },
    {
      path: '/contato',
      element: <ContactPage/>,
      name: "Contato"
    },
    {
      path: '/bebida-detalhe/:id',
      element: <OurDrinksDetailPage/>,
      name: "Bebida detalhe"
    },
    {
      path: '*',
      element: <NotFoundPage />,
      name: 'Página não encontrada'
    }
  ];
  
  export default routeConfig;