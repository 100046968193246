import { useState, useEffect } from 'react';

const useAgeConsent = () => {
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    const consent = sessionStorage.getItem('ageConsentGiven') === 'true';
    setConsentGiven(consent);
  }, []);

  const giveConsent = () => {
    sessionStorage.setItem('ageConsentGiven', 'true');
    setConsentGiven(true);
  };

  return [consentGiven, giveConsent];
};

export default useAgeConsent;